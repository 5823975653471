import React, { useState } from "react";
import { LogoText } from "../DBIcons";
import { LogoFade, LogoTextWhite } from "../icons";
import { DropBtn } from "../buttons";
import "./styles.scss";
import web_logo from "../../assets/images/web_logo.png";
import header_logo from "../../assets/images/header_logo.png";
import { Link } from "react-router-dom";
import Drop from "../drop";

import {
  HOME,
  TEST_KIST,
  PLANS,
  BLOG,
  ALL_SOLUTIONS,
  HOSPITAL_SOLUTIONS,
  PHARMACY_SOLUTION,
  DIAGNOSTICS_SOLUTION,
  HEALTH_INSURANCE_SOLUTION,
  COMPANY_AND_NGO_SOLUTION,
  CONTACT_US,
  FAQ,
  ABOUT_US,
  INDEPENDENT_SOLUTION,
  CORE_VALUES,
  VISSION_MISSION,
  SUPPOORT,
  SUPPORT,
} from "../../../router/router";
import NavToggleBtn from "../SideNavToggle";
const Header = ({
  home,
  solutions,
  test,
  support,
  register,
  login,
  className,
  dropClass,
  onClick,
  isDark,
  navClick,
  navClosed,
}) => {
  const links = [
    { name: "HOME", link: HOME, active: home },
    { name: "SOLUTIONS", link: ALL_SOLUTIONS, active: solutions },
    { name: "TEST KITS", link: TEST_KIST, active: test },
    // { name: "PRICING", link: PLANS, active: pricing },
    { name: "SUPPORT", link: CONTACT_US, active: support },
    {
      name: "REGISTER",
      link: "https://app.totalhealthcare360.com/register",
      active: register,
    },
    {
      name: "LOGIN",
      link: "https://app.totalhealthcare360.com/",
      active: login,
    },
  ];

  const sols = [
    {
      name: "Independent Doctors",
      link: INDEPENDENT_SOLUTION,
    },
    { name: "Hospitals", link: HOSPITAL_SOLUTIONS },
    // PHARMACY_SOLUTION
    // { name: "Pharmacies", link: "/solutions/pharmacies" },
    { name: "Pharmacies", link: PHARMACY_SOLUTION },
    // { name: "Diagnostic Centres", link: "/solutions/diagnostic-centers" },
    { name: "Diagnostic Centres", link: DIAGNOSTICS_SOLUTION },
    // { name: "Health Insurance Provider", link: "/solutions/hmos" },
    { name: "Health Insurance Provider", link: HEALTH_INSURANCE_SOLUTION },
    { name: "Companies & NGO's", link: COMPANY_AND_NGO_SOLUTION },
  ];

  const homs = [
    { name: "About", link: ABOUT_US },
    { name: "Core Values", link: CORE_VALUES },
    { name: "Vision & Mission", link: VISSION_MISSION },
    // { name: "Careers", link: "/Careers" },
  ];
  const blo = [
    { name: "Contact Us", link: CONTACT_US },
    { name: "FAQ", link: FAQ },
    {
      name: "Blog",
      // link: BLOG
    },
  ];

  const [showSolutions, setShowSolutions] = useState(false);
  const [showRegs, setShowRegs] = useState(false);
  const [showHome, setShowHome] = useState(false);
  const [showBlog, setShowBlog] = useState(false);
  return (
    <header className={`py-2 app-header mb-2 ${className}`}>
      <div
        className="website_content mt-1 d-justify-between d-align-center"
        style={{ height: "2.4rem" }}
      >
        <Link
          to={HOME}
          className="fit-content d-justify-center d-align-center mr-5 "
        >
          <img
            src={web_logo}
            style={{
              width: "2.4rem",
              height: "2.4rem",
              marginRight: "0.1rem",
            }}
          />

          {isDark ? (
            <div style={{ marginTop: "1rem" }}>
              <LogoTextWhite width="90" height="40" />
            </div>
          ) : (
            <LogoText width="90" height="30" />
          )}
        </Link>
        <ul className="d-justify-between d-align-center w-100 ml-5 mb-1 nav_list desktop">
          {links.map((item, i) => {
            return (
              <li
                key={item + i}
                className={`mr-3 ${item.name === "SUPPORT" ? "" : ""}`}
                onMouseEnter={() => {
                  if (item.name === "SOLUTIONS") setShowSolutions(true);
                  else if (item.name === "HOME") setShowHome(true);
                  else if (item.name === "REGISTER") setShowRegs(true);
                  else if (item.name === "SUPPORT") setShowBlog(true);
                }}
                onMouseLeave={() => {
                  if (item.name === "SOLUTIONS") setShowSolutions(false);
                  else if (item.name === "HOME") setShowHome(false);
                  else if (item.name === "REGISTER") setShowRegs(false);
                  else if (item.name === "SUPPORT") setShowBlog(false);
                }}
              >
                {item.name === "REGISTER" || item.name === "LOGIN" ? (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`no-wrap inter text-n text-b ${item.active}`}
                  >
                    {item.name}
                  </a>
                ) : (
                  <Link
                    to={item.link}
                    className={`no-wrap inter text-n text-b ${item.active}`}
                  >
                    {item.name}
                  </Link>
                )}
                <Drop
                  data={homs}
                  show={item.name === "HOME" && showHome}
                  onClick={onClick}
                />
                <Drop
                  data={blo}
                  show={item.name === "SUPPORT" && showBlog}
                  onClick={onClick}
                />
                <Drop
                  data={sols}
                  show={item.name === "SOLUTIONS" && showSolutions}
                  onClick={onClick}
                />
              </li>
            );
          })}

          <DropBtn lang className={`mr-1 ${dropClass}`} />
          <DropBtn className={`${dropClass}`} />
        </ul>
        <NavToggleBtn
          click={navClick}
          className="mobile hamburger_btn_alt"
          close={navClosed}
        />
      </div>
    </header>
  );
};
export default Header;
