import React, { useState } from "react";
import { ChevronDownThin } from "../DBIcons";
import { Chinese } from "../icons/index";
import "./styles.scss";
const DropBtn = ({ lang, className }) => {
  const [showOption, setShowOption] = useState(false);
  return (
    <button
      className={`flex-row d-justify-center d-align-center primary rounded ease p-0 bg-none drop-btn ${className}`}
      onMouseEnter={() => {
        setShowOption(true);
      }}
      onMouseLeave={() => {
        setShowOption(false);
      }}
      style={{ height: "1.9rem" }}
    >
      {lang ? (
        <Chinese width="18px" height="18px" className="mr-2" fill="#071232" />
      ) : null}
      <ChevronDownThin width="10px" height="10px" />
    </button>
  );
};
const Button = ({ text, className, onClick }) => {
  return (
    <button
      className={`rounded-lg py-1 px-5 width-fit aileron ${className}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const Button2 = ({ text, className, onClick }) => {
  return (
    <button
      className={`rounded-lg py-1 px-5  aileron ${className}`}
      onClick={onClick}
      style={{ width: "16rem" }}
    >
      {text}
    </button>
  );
};
const AppButton = ({ icon, className, text, type, onClick }) => {
  return (
    <button
      //  ref={(el) => (ref = el)}
      onClick={onClick}
      className={`rounded-lg py-1 px-lg-4 px-2 d-flex apps-btn d-center ${className}`}
      style={{ height: "2.7rem" }}
    >
      <div className="mr-1"> {icon}</div>
      <div className="flex-column d-justify-start d-align-start">
        <p className="xxs-small mb-0 product-sans bold text-sm" style={{ color: 'white' }}>{text}</p>
        <p
          className="no-wrap mb-0 product-sans bold text-20 py-sm"
          id="product-sans"
          style={{ color: 'white' }}
        >
          {type}
        </p>
      </div>
    </button>
  );
};
export { DropBtn, Button, AppButton, Button2 };
