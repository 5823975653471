import React, { useRef, useEffect, useState } from "react";
import Family from "../../assets/images/family.jpg";
import { AppButton } from "../../components/buttons";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaApple } from "react-icons/fa";
import { textIntro, textIntroTwo, play, apple, bannerPic, scrollCards, slider } from "./animate";
import { Button } from "../../components/buttons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import history from "history/browser";

const imageSlider= [
 {
    id: 1,
    image: require('../../assets/images/thc_system_logo.bmp'),
 },
 
];

export const CarouselPage = ({ color = 'textColor' }) => {
 const ref = useRef(null);
 const [imgActive, setImageActive] = useState(0);
 const thisTimeout = useRef(null);

 useEffect(() => {
  console.log("kckckkn")
  if (ref.current) {
     const width = ref.current.offsetWidth;
     ref.current.scrollTo({
       left: imgActive === 0 ? 0 : width,
       behavior: 'smooth',
     });
     thisTimeout.current = setTimeout(() => {
       setImageActive(imgActive >= imageSlider.length - 1 ? 0 : imgActive + 1);
     }, 400);
  }
 
  return () => {
     if (thisTimeout.current) clearTimeout(thisTimeout.current);
  };
 }, [imgActive]);
 

 return (
    <div>
      {imageSlider.map((_, index) => (
        <img
          key={index}
          alt="screen"
          src={_.image}
          className={`mt-3 patient_screen ${index === imgActive ? 'visible' : ''}`}
          style={{width:"100rem"}}
          
        />
      ))}
    </div>
 );
};

const Banner = ({ onScrollClick, src, onLoad, onError }) => {
 const [currentSlide, setCurrentSlide] = useState(0);
 let intro = useRef(null);
 let introTwo = useRef(null);
 let playRef = useRef(null);
 let appleRef = useRef(null);
 let famRef = useRef(null);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
    textIntro(intro);
    textIntroTwo(introTwo);
    play(playRef);
    apple(appleRef);
    // bannerPic(famRef);
 }, []);

 return (
    <div className="d-justify-between d-align-start mt-5 mb-5 flex-wrap mb-5 home_banner">
      <div className="flex flex-row mt-3 items-start justify-start my-auto py-5 home_banner_content">
        <div className="">
          <h1 ref={(el) => (intro = el)} className="text-3xl font-bold home_banner_text">
            Empowering healthcare

          </h1>
          <h1 ref={(el) => (intro = el)} className="text mt-n4 font-bold home_banner_text">
          providers, elevating 
          </h1>
          <h1 ref={(el) => (intro = el)} className="text mt-n4 font-bold home_banner_text">
        
            patient care
          </h1>
        </div>

        <p
          ref={(el) => (introTwo = el)}
          className="text-light pb-5 mb-5 mb-lg-4 pb-lg-1 aileron text-left w-60 text-lg-left  home_sub_Text"
        >
          <span className="nowrap aileron nowrap">
            Unleash the potential for prosperity in every journey to better
            <br />health. Health care access anytime, anywhere, 24/7.
          </span>
        </p>
        <div className="d-justify-start d-align-start home_button">
          <div ref={(el) => (playRef = el)} className="mr-2">
            <AppButton
            onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=thc.mobile'}
              icon={
                <IoLogoGooglePlaystore
                 width="30px"
                 height="30px"
                 fill="#fff"
                 style={{ transform: "scale(1.6)" }}
                 className="mr-1"
                />
              }
              text="Get it on"
              type="Play Store"
              className="btn-dark"
            />
          </div>

          <div ref={(el) => (appleRef = el)}>
            <AppButton
            onClick={() => window.location.href = 'https://apps.apple.com/gb/app/total-healthcare/id6463031222'}
              icon={
                <FaApple
                 width="30px"
                 height="30px"
                 fill="#fff"
                 style={{ transform: "scale(2)" }}
                 className="mr-1"
                />
              }
              text="Download on the"
              type="App Store"
              className="btn-dark"
            />
          </div>
        </div>

      </div>
      <div className=" mt-5 home_image">
      <img
          
          alt="screen"
          src={require('../../assets/images/thc_system_logo.bmp')}
      
          style={{width:"20rem"}}
          className="image_container"
          
        />
        </div>
       {/* <div ref={(el) => (famRef = el)} className="col-12 col-md-6 mt-5 image">
        <CarouselPage/>
      </div>  */}

   
    </div>
 );
};

export default Banner;
